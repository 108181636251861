import React from 'react'
import Layout from '../components/layout'
import {
  projectBody,
} from '../components/layout.module.scss'
import Seo from '../components/seo'
import ContactForm from '../components/contactForm'


export default function ContactUs({ location }) {
  let isContact = false;
  const canonicalUrl = location.pathname.replace(/[^\w\s]/gi, "")

  if (canonicalUrl === "contact") {
      isContact = true;
  } 
  
  return (
    <Layout pageTitle="Contact Us">
    <Seo
      title="Contact Us"
      description="BRADIENT, contact us today to learn how we can help grow your business." 
    />
      <div className={projectBody}>
        <div className='grid grid-col-1 lg:grid-cols-12 md:gap-5'>
          <div className='lg:col-span-6'>
            <ContactForm isContact={true} />
          </div>
          <div className='' />
          <div className='hidden lg:block lg:col-span-5'>
                <div className='mb-5 md:pt-5 w-full inline-block lg:float-right lg:pt-0'>
                  <div className='text-left lg:text-right lg:pr-2 text-sm' itemProp="LocalBusiness" itemScope itemType="https://schema.org/ProfessionalService">
                    <meta itemProp="currenciesAccepted" content="USD" />
                    <meta itemProp="paymentAccepted" content="Cash, Credit Card" />
                    <meta itemProp="priceRange" content="5000-50000" />
                    <meta itemProp="image" content="<%= asset_path('logos/bradient.png') %>" />
                    <meta itemProp="openingHours" content="Mo-Fr 9:00-18:00"/>
                    <h3 className='text-lg uppercase text-black mb-2 inline-block w-full font-medium' itemProp="name">BRADIENT</h3>
                    <div itemProp="address" itemScope itemType="http://schema.org/PostalAddress">
                      <span itemProp="streetAddress">1150 S Olive St, 10th Floor</span>
                      <br />
                      <span itemProp="addressLocality">Los Angeles</span>,
                      <span itemProp="addressRegion">&nbsp;CA</span>
                      <span itemProp="postalCode">&nbsp;90015</span>
                    </div>
                    <div className='inline-block w-full mt-4'>
                      <span>Tel: </span> 
                      <span itemProp="telephone"><a href="tel:844-454-4218">(844) 454-4218</a></span>
                    </div>
                  </div>
                </div>
          </div>
        </div>   
      </div>
    </Layout>
  )
}